import React, { useState } from "react"

//components
import PromoSlider from "@components/cabinet/sliders/PromoSlider"
import FavoriteProductCard from "@/components/cabinet/product-cards/FavoriteProductCard"
import PopUp from "@components/shared/popup/PopUp"
import ConfirmFavoriteProduct from "@components/cabinet/popup-content/ConfirmFavoriteProduct"

const FavoriteProductsPage = () => {
  const [isConfirmPopUpOpen, setConfirmPopUpState] = useState(false)

  const ConfirmFavoritePopUp = () => {
    return (
      <PopUp onClose={() => setConfirmPopUpState(false)}>
        <ConfirmFavoriteProduct />
      </PopUp>
    )
  }

  const handleSelectFavoriteProduct = () => {
    setConfirmPopUpState(true)
  }

  return (
    <>
      <div className="favorite-products-page">
        <div className="profile-page__slider-wrapper">
          <PromoSlider />
        </div>
        <div className="favorite-products-page__message flex">
          <p className="favorite-products-page__message-text">
            Пластиковую карту вы можете получить в любом магазине Small. Для участия в программе
            лояльности не обязательно иметь пластиковую карту, достаточно электронной.
            <a
              className="link--red"
              href="http://google.ru"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" Скачайте наше приложение "}
            </a>
            , чтобы копить бонусы и пользоваться всеми привилегиями!
          </p>
        </div>
        <div className="favorite-products-page__content">
          <h2 className="favorite-products-page__content-title secondary-title--small">
            Выберите любимый товар
          </h2>
          <div className="favorite-products-page__content-list list">
            <FavoriteProductCard onClick={handleSelectFavoriteProduct} />
            <FavoriteProductCard onClick={handleSelectFavoriteProduct} isSelected />
            <FavoriteProductCard onClick={handleSelectFavoriteProduct} />
            <FavoriteProductCard onClick={handleSelectFavoriteProduct} />
          </div>
        </div>
      </div>

      {isConfirmPopUpOpen && <ConfirmFavoritePopUp />}
    </>
  )
}

export default FavoriteProductsPage
