import React from "react"
import { useSelector } from "react-redux"

//components
import SmallCardActive from "@components/cabinet/small-card/SmallCardActive"
import SmallCardInactive from "@components/cabinet/small-card/SmallCardInactive"

//assets
import GooglePlayImage from "@assets/images/google.svg"
import AppStoreImage from "@assets/images/apple.svg"

const SmallCardPage = () => {
  const hasSmallCard = useSelector((state) => state.profileSlice.hasCard)

  return (
    <div className="small-card-page flex">
      <h2 className="small-card-page__title">Карта Small</h2>
      {hasSmallCard ? <SmallCardActive /> : <SmallCardInactive />}
      <div className="small-card-page__marketplaces">
        <a href="https://google.ru" className="marketplace small-card-page__market-google">
          <img src={GooglePlayImage} />
        </a>
        <a href="https://google.ru" className="marketplace small-card-page__market-apple">
          <img src={AppStoreImage} />
        </a>
      </div>
    </div>
  )
}

export default SmallCardPage
