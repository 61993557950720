import { useState } from "react"

const useFormField = (initValue) => {
  const [input, setValue] = useState({
    value: initValue,
    error: null,
    isErrorShown: false,
  })

  const handleChangeValue = (newValue) => {
    setValue((old) => ({ ...old, error: null, isErrorShown: false, value: newValue }))
  }

  const handleSetError = (error) => {
    setValue((old) => ({ ...old, error }))
  }

  const handleSetErrorShownState = () => {
    setValue((old) => ({ ...old, isErrorShown: true }))
  }

  const setters = {
    changeValue: handleChangeValue,
    setError: handleSetError,
    showError: handleSetErrorShownState,
  }

  return { input, setters }
}

export default useFormField
