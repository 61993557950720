import React, { useState } from 'react'
import styles from './ContactsForm.module.scss'

//components
import PopUp from '@components/shared/popup/PopUp'
import ChangePassword from '@components/cabinet/popup-content/ChangePassword'

const ContactsForm = () => {
  const [isChangePassPopUpOpen, setChangePassPopUpState] = useState(false)

  const ChangePasswordPopUp = () => {
    return (
      <PopUp onClose={() => setChangePassPopUpState(false)}>
        <ChangePassword />
      </PopUp>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <div className={`${styles.wrapper} flex`}>
        <h2 className={styles.title}>Контактная информация</h2>

        <form className={`${styles.form}`} onSubmit={handleSubmit}>
          <div className={`form-row`}>
            <input
              type="text"
              name="fullname"
              className={`input form__input`}
              placeholder="Фамилия Имя"
            />
          </div>
          <div className={`form-row`}>
            <input
              type="text"
              name="phoneNumber"
              className={`input form__input`}
              placeholder="Номер телефона"
            />
          </div>
          <div className={`form-row`}>
            <input type="email" name="email" className={`input form__input`} placeholder="E-mail" />
          </div>
          <div className={`form-row`}>
            <input
              type="text"
              name="birthday"
              className={`input form__input`}
              placeholder="Дата рождения (дд/мм/гг)"
            />
          </div>
          <div className={`form-row ${styles['from-row-password']}`}>
            <input
              type="text"
              name="password"
              className={`input form__input`}
              placeholder="Пароль"
            />
          </div>
          <div className={`form-row`}>
            <p
              className={`button ${styles['button-change-password']}`}
              onClick={() => setChangePassPopUpState(true)}
            >
              Изменить пароль
            </p>
          </div>
          <div className={`${styles['form-actions']} flex`}>
            <h4 className={styles['form-actions__title']}>Укажите свой пол</h4>
            <div className={`${styles['checkboxes-wrapper']} flex`}>
              <div className="custom-checkbox__wrapper">
                <input className="custom-checkbox__input" type="checkbox" id="male" />
                <label className="custom-checkbox__label" htmlFor="male">
                  Мужской
                </label>
              </div>
              <div className="custom-checkbox__wrapper">
                <input className="custom-checkbox__input" type="checkbox" id="female" />
                <label className="custom-checkbox__label" htmlFor="female">
                  Женский
                </label>
              </div>
            </div>
            <button
              className={`${styles['button-submit']} button button--red-light`}
              onClick={handleSubmit}
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
      {isChangePassPopUpOpen && <ChangePasswordPopUp />}
    </>
  )
}

export default ContactsForm
