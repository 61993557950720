import React from "react"

//components
import BarBrush from "@components/cabinet/my-purchases/bar-brush/BarBrush"

const MyPurcharesAnalysisPage = () => {
  // const isNotUsedSmallCard = true

  return (
    <div className="my-purchases-analysis-page">
      <div className="my-purchases-analysis-page__empty-chart-wrapper flex">
        <div className="my-purchases-analysis-page__empty-chart">
          <span></span>
        </div>
        <div className="my-purchases-analysis-page__empty-description-wrapper">
          <p className="my-purchases-analysis-page__empty-description">
            Начните использовать карту SMALL при оплате покупок в наших магазинах, чтобы следить за
            своими тратами
          </p>
          <div className="my-purchases-analysis-page__empty-statics flex">
            <p className="my-purchases-analysis-page__empty-statics-left">
              <span className="my-purchases-analysis-page__circle"></span>
              <span className="my-purchases-analysis-page__text">Нет информации</span>
            </p>
            <p className="my-purchases-analysis-page__empty-statics-right">0</p>
          </div>
        </div>
      </div>

      <div className="my-purchases-analysis-page__empty-bottom flex">
        <div className="my-purchases-analysis-page__empty-bottom-left">
          <BarBrush />
        </div>
        <div className="my-purchases-analysis-page__empty-bottom-right">
          <div className="empty-circle-chart-wrapper">
            <div className="empty-circle-chart">
              <p className="empty-circle-chart__text">0%</p>
            </div>
            <div className="empty-circle-chart__lables">
              <p className="empty-circle-chart__summary">
                <span></span>Сумма покупок 0
              </p>
              <p className="empty-circle-chart__econom">
                {" "}
                <span></span> Экономия 0
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyPurcharesAnalysisPage
