import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Header from '@components/shared/Header'
import Breadcrumb from '@components/shared/Breadcrumb'
import CabinetNavigation from '@components/cabinet/CabinetNavigation'

const MainCabinet = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const isLoggedIn = true
  const isVisibleMenu = useSelector((state) => state.uiSlice.isVisibleMenu)

  useEffect(() => {
    // auth logic wasn't ready in back
    localStorage.setItem('token', '130|l3Yv6G7u7ZM45gbd9cjIgP793B27mJgb81pVIbjv')

    if (location.pathname === '/cabinet') isLoggedIn ? navigate('/cabinet/profile') : navigate('/')
  }, [isLoggedIn])

  return (
    <div className="layout">
      <Header />
      <div className="container">
        <div className="wrapper">
          <Breadcrumb />
          <div className="cabinet flex">
            <div className={isVisibleMenu ? 'aside mobile-aside' : 'aside'}>
              <CabinetNavigation />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainCabinet
