import React from "react"
import ReactDOM from "react-dom/client"
import { store } from "./store"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals"

import "@assets/scss/style.scss"

import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
reportWebVitals()
