import React, { useEffect } from "react"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"

//components
import RangeDatepicker from "@components/cabinet/my-purchases/range-datepicker/RangeDatepicker"

const MyPurCharesPage = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname === "/cabinet/my-purchases") {
      navigate("/cabinet/my-purchases/analysis")
    }
  }, [pathname])

  return (
    <div className="my-purchases-page flex">
      <div className="my-purchases-page__header flex">
        <div className="my-purchases-page__nav-wrapper">
          <nav className="my-purchases-page__nav">
            <NavLink className="my-purchases-page__nav-item" to="/cabinet/my-purchases/analysis">
              Анализ покупок
            </NavLink>
            <NavLink className="my-purchases-page__nav-item" to="/cabinet/my-purchases/transaction">
              Транзакции
            </NavLink>
          </nav>
        </div>
        <div className="my-purchases-pagee__datepicker-wrapper">
          <RangeDatepicker />
        </div>
      </div>
      <Outlet />
    </div>
  )
}

export default MyPurCharesPage
