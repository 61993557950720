import http from '@api'

const enpoints = {
  user: '/cabinet/profile/get-small-user',
}

const profileService = {
  getUserData: async () => {
    const response = await http.get(enpoints.user)
    return response.data
  },
}

export default profileService
