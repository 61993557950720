import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

const ClubCardLarge = (props) => {
  const buttonClassName = props.isJoined ? 'button--red-light' : 'button--red'

  return (
    <div className={'club-card-large'}>
      <div className={'club-card-large__image-wrapper'}>
        <img className={'club-card-large__image'} src={props.image} />
        <button className={cn('button club-card-large__button-mobile', buttonClassName)}>
          {props.isJoined ? 'Покинуть клуб' : 'Вступить в клуб'}
        </button>
      </div>
      <div className={'club-card-large__desc-wrapper flex'}>
        <h4 className={'club-card-large__title'}>{props.title}</h4>
        <p className={'club-card-large__desc-text'}>{props.description}</p>
        <div className={'club-card-large__actions flex'}>
          <Link to={`${props.id}`} className={'button button-readmore'}>
            Подробнее о клубе
          </Link>
          <button
            className={cn('button club-card-large__button', buttonClassName)}
            disabled={props.isSubmitting}
            onClick={props.onAction}
          >
            {props.isJoined ? 'Покинуть клуб' : 'Вступить в клуб'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ClubCardLarge
