import { configureStore } from '@reduxjs/toolkit'

//slices
import authSlice from './slices/authSlice'
import uiSlice from './slices/uiSlice'
import profileSlice from './slices/profileSlice'
import clubsSlice from './slices/clubsSlice'

export const store = configureStore({
  reducer: {
    authSlice,
    uiSlice,
    profileSlice,
    clubsSlice,
  },
})
