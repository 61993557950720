import React, { useEffect } from "react"
import cn from "classnames"

//utils
import { fieldValidator } from "@/utils/validators/form-validators"

// add credit card validators (**** - **** - **** - ****)
const CardNumberInputField = ({
  required = false,
  groupClasses = "",
  inputClasses = "",
  errorClasses = "",
  placeholder = "Поле",
  ...props
}) => {
  const { input, setters } = props.meta
  const hasError = input.isErrorShown && input.error

  // validating filed requires first touch, to avoid this problem
  useEffect(() => {
    if (input.value === "") setters.setError(fieldValidator(input.value))
  }, [])

  const handleChange = (e) => {
    const value = e.target.value

    setters.changeValue(value)

    if (fieldValidator(value)) {
      setters.setError(fieldValidator(value))
    } else setters.setError(null)
  }

  return (
    <div className={cn("form__group", groupClasses)}>
      <input
        value={input.value}
        required={required}
        className={cn("form__input", inputClasses, hasError && "error")}
        type={"number"}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {hasError && <span className={cn("form__error", errorClasses)}>{input.error}</span>}
    </div>
  )
}

export default CardNumberInputField
