import React from 'react-dom'
import { Link } from 'react-router-dom'

import CircleStatus from '@components/shared/icons/CircleStatus'

const UserInfo = (props) => {
  return (
    <div className={`user-info flex ${props.className}`}>
      <div className="user-info__card flex">
        <div className="user-info__card-body">
          <h4 className="user-info__fullname">
            {props.lastName} {props.firstName}
          </h4>
          <p className="user-info__email">{props.email}</p>
        </div>
        <div className="user-info__card-action">
          <Link to="/cabinet/contacts" className="user-info__button-edit flex">
            <span className="user-info__button-edit-text">Редактировать</span>
            <span className="icon user-info__icon-edit"></span>
          </Link>
        </div>
      </div>
      <Link to="/cabinet/small-card" className="bonus-statics ">
        <p className="bonus-statics__number">4905533152300</p>
        <p className="bonus-statics__title">Мои бонусы</p>
        <p className="bonus-statics__bonuses">123</p>
        <p className="bonus-statics__status-wrapper flex">
          <CircleStatus status="active" />
        </p>
      </Link>
    </div>
  )
}

export default UserInfo
