import React, { useEffect } from "react"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"

// components
import BirthdayBanner from "@components/cabinet/bonus-coupon/birthday-banner/BirthdayBanner"

const BonusCoupon = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname === "/cabinet/bonuses-coupons") {
      navigate("/cabinet/bonuses-coupons/all")
    }
  }, [pathname])

  return (
    <div className="bonus-coupon-page">
      <BirthdayBanner />
      <div className="bonus-coupon-page__header ">
        <p className="bonus-coupon-page__label">Мой баланс</p>
        <p className="bonus-coupon-page__bonus">50 Бонусов</p>
        <p className="bonus-coupon-page__message">1 бонус = 1 тенге</p>
      </div>
      <nav className="bonus-coupon-page__nav">
        <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/all">
          Все купоны
        </NavLink>
        <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/my">
          Мои купоны
        </NavLink>
        <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/archive">
          Архив
        </NavLink>
      </nav>
      <Outlet />
    </div>
  )
}

export default BonusCoupon
