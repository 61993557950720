import React from "react"

// components
import ProductCard from "@components/cabinet/product-cards/ProductCard"

// assets
import BirthdayBannerBg from "@assets/images/temp/birthday-bg.jpg" // This bg from backend for mobile and desktop

const CouponesPage = () => {
  const items = [1, 2, 3, 4, 44, 444, 2344, 44322, 34344, 4111, 433334, 43332222]

  return (
    <div className="couponse-page">
      <div className="list">
        <div
          className="couponse-page__item-birthday flex"
          style={{ backgroundImage: `url(${BirthdayBannerBg})` }}
        >
          <h3 className="couponse-page__birthday-title">ДАРИМ КУПОН!</h3>
          <p className="couponse-page__birthday-description">
            -15% на продукцию собственного производства и -5% на все остальное (кроме акционных
            товаров, алкоголя, табака и СЗПТ)
          </p>
          <button className="button couponse-page__button-get-coupon">Получить купон</button>
        </div>
        {items.map((p) => (
          <ProductCard key={p} buttonText="Купить" isActiveButton />
        ))}
      </div>
    </div>
  )
}

export default CouponesPage
