import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//store
import { loadClubs, joinClub, leaveClub } from '@/store/slices/clubsSlice'

//components
import ClubCardLarge from '@components/cabinet/club/ClubCardLarge'

const ClubItemsList = () => {
  const dispatch = useDispatch()

  const clubs = useSelector((state) => state.clubsSlice.clubs)
  const isSubmitting = useSelector((state) => state.clubsSlice.isSubmitting)

  useEffect(() => {
    dispatch(loadClubs())
  }, [])

  const handleJoinClub = (isInThisClub, clubSet) => {
    if (isInThisClub) {
      dispatch(leaveClub(clubSet))
    } else {
      dispatch(joinClub(clubSet))
    }
  }

  const clubItems = clubs
    ? clubs.map((item) => {
        return (
          <ClubCardLarge
            key={item.id}
            id={item.id}
            image={item.img}
            title={item.title_ru}
            description={item.description_ru}
            isJoined={item.in_segment}
            isSubmitting={isSubmitting}
            onAction={() => handleJoinClub(item.in_segment, item.set_id)}
          />
        )
      })
    : []

  return (
    <div>
      <div className="club-items-list__message flex">
        <p className="club-items-list__message-text">
          Клуб можно менять не чаще 1 раза в месяц. Список SKU обновляется 1 раз в 1 месяц
        </p>
      </div>
      <div className="club-items-list__list flex">{clubItems}</div>
    </div>
  )
}

export default ClubItemsList
