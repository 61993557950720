import React from "react"
import { Link } from "react-router-dom"

// Temp image
import ClubZojImage from "@assets/images/temp/club-zoj.png"
// import ClubChildImage from '@assets/images/temp/club-zoj.png'

const ClubCardSmall = ({ backgroundColor, className }) => {
  const style = {
    backgroundColor,
  }
  return (
    <Link to="/cabinet/clubs/1" className={`club-card-small-wrapper ${className}`}>
      <div className="club-card-small flex" style={style}>
        <img className="club-card-small__image" src={ClubZojImage} alt="" />
        <button className="club-card-small__button button ">“ЗОЖ” </button>
      </div>
    </Link>
  )
}

export default ClubCardSmall
