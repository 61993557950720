import React from "react"
import { Route, Routes } from "react-router-dom"

import CabinetLayout from "@/layouts/MainCabinet"

// cabinet Views
import ProfilePage from "@views/cabinet/ProfilePage"
import SmallCardPage from "@views/cabinet/SmallCardPage"

import BonusCouponPage from "@views/cabinet/bonus-coupon/BonusCouponPage"
import CopunesPage from "@views/cabinet/bonus-coupon/CouponesPage"
import MyCouponesPage from "@views/cabinet/bonus-coupon/MyCouponesPage"
import ArchiveCopunesPage from "@views/cabinet/bonus-coupon/ArchiveCopunesPage"

import MyPurchasesPage from "@views/cabinet/my-purchases/MyPurchasesPage"
import MyPurchasesAnalysisPage from "@views/cabinet/my-purchases/MyPurchasesAnalysisPage"
import MyPurchasesTransactionPage from "@views/cabinet/my-purchases/MyPurchasesTransactionPage"

import ClubsPage from "@views/cabinet/clubs/ClubsPage"
import ClubItemsList from "@views/cabinet/clubs/ClubItemsList"
import ClubInnerPage from "@views/cabinet/clubs/ClubInnerPage"

import ContactsPage from "@views/cabinet/ContactsPage"

import FavoriteProductsPage from "@views//cabinet/FavoriteProductsPage"
import LoginPage from "@views/site/LoginPage"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { setToken, setIsLoggedIn, setUser } from "@/store/slices/authSlice"

const Routers = () => {
  const token = localStorage.getItem("token")
  const user = localStorage.getItem("user")

  const isLoggedIn = useSelector((state) => state.authSlice.isLoggedIn)

  const navigate = useNavigate()
  useEffect(() => {
    if (token) {
      setIsLoggedIn(true)
      setUser(user) // response.data.user
      setToken(token)
    }

    if (!isLoggedIn) {
      navigate("/login")
    }
  }, [isLoggedIn])

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/cabinet" element={<CabinetLayout />}>
        <Route path="profile" index element={<ProfilePage />} />
        <Route path="small-card" element={<SmallCardPage />} />
        <Route path="bonuses-coupons" element={<BonusCouponPage />}>
          <Route path="all" element={<CopunesPage />}></Route>
          <Route path="my" element={<MyCouponesPage />}></Route>
          <Route path="archive" element={<ArchiveCopunesPage />}></Route>
        </Route>
        <Route path="my-purchases" element={<MyPurchasesPage />}>
          <Route path="analysis" element={<MyPurchasesAnalysisPage />} />
          <Route path="transaction" element={<MyPurchasesTransactionPage />} />
        </Route>
        <Route path="clubs" element={<ClubsPage />}>
          <Route path="" index element={<ClubItemsList />} />
          <Route path=":id" element={<ClubInnerPage />} />
        </Route>
        <Route path="contacts" element={<ContactsPage />} />
        <Route path="favorite-products" element={<FavoriteProductsPage />} />
      </Route>
    </Routes>
  )
}

export default Routers
