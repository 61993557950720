import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import authAPI from "@/services/authService"

//init
const name = "authSlice"
const initialState = {
  isLoggedIn: true,
  token: "faketoken",
  user: null,
}

//slice
export const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setUser: (state, action) => {
      state.token = action.payload
    },
  },
  extraReducers: (builder) => {
    //REGISTER
    builder.addCase(register.fulfilled, (state, action) => {
      state.user = action.payload
      state.token = action.payload?.token
      state.isLoggedIn = true
    })
  },
})

//thunks
export const register = createAsyncThunk(`${name}/register`, async (userData) => {
  const response = await authAPI.handleRegister(userData)
  return response
})

//actions
export const { setIsLoggedIn, setToken, setUser } = authSlice.actions

export default authSlice.reducer
