import React from "react-dom"
import { Link } from "react-router-dom"
import ReactECharts from "echarts-for-react"

const PurchaseAnalysisCard = () => {
  const options = {
    tooltip: {
      trigger: "item",
      show: false,
    },
    legend: {
      show: false,
    },
    responsive: true,

    itemStyle: {
      borderRadius: 20,
      borderColor: "#fff",
      borderWidth: 0,
    },

    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["55%", "70%"],
        label: {
          show: true,
          position: "center",
          fontSize: "30",
        },
        center: ["50%", "50%"],

        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        color: ["rgb(81,207,102)", "rgb(203,241,209)"],

        data: [
          { value: 24, name: "24", select: { disabled: false } },

          { value: 76, name: "100" },
        ],
      },
    ],
  }

  return (
    <div className="purchase-analysis">
      <h4 className="purchase-analysis__title">Анализ покупок</h4>
      <div className="purchase-analysis__diagram-wrapper flex">
        <div className="purchase-analysis__diagram">
          <ReactECharts option={options} />
        </div>
        <div className="purchase-analysis__diagram-text">
          <p className="purchase-analysis__summary">Сумма покупок 28.000</p>
          <p className="purchase-analysis__saving-money">Экономия 6.600 </p>
          <Link
            to="/cabinet/my-purchases/analysis"
            className="purchase-analysis__readmore button-readmore"
          >
            Узнать подробнее
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PurchaseAnalysisCard
