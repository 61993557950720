/* eslint-disable no-undef */
import axios from "axios"

const token = localStorage.getItem("token")

const baseURL = process.env.REACT_APP_BASE_API_URL
const headers = {}

if (token) {
  headers.Authorization = `Bearer ${token}`
}

const http = axios.create({
  baseURL,
  headers,
})

export { http }
