import React from 'react'

//hooks
import useFormField from '@/utils/hooks/useFormField'

//components
import TextInputField from '@components/shared/form/TextInputField'

const ForgetPasswordForm = ({ changeFormType }) => {
  const email = useFormField('')

  const formFields = {
    email,
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()

    //validate form fields before sumbit
    if (Object.values(formFields).some((field) => field.input.error)) {
      Object.values(formFields).forEach((field) => field.setters.showError())

      return
    }
  }

  return (
    <form className={'auth-form'} onSubmit={handleSubmitForm}>
      <h4 className={'auth-form__title'}>Забыли пароль?</h4>
      <div className={'auth-form__body'}>
        <TextInputField meta={email} type="email" placeholder="Почта" />
      </div>
      <div className={'auth-form__footer'}>
        <div className="auth-form__actoins-wrapper">
          <button
            type="button"
            className="auth-form__button-action button button--outlined"
            onClick={() => changeFormType('login')}
          >
            Назад
          </button>
        </div>
      </div>
    </form>
  )
}

export default ForgetPasswordForm
