import React from "react"

//assets
import PrductImage from "@assets/images/temp/product.png"

const ClubProductCard = () => {
  return (
    <div className="club-product-card">
      <div className="club-product-card__image-wrapper">
        <div className="club-product-card__image">
          <img src={PrductImage} />
        </div>
        <div className="club-product-card__price">
          <div className="club-product-card__price-discount">-15%</div>
          <div className="club-product-card__price-content">
            <span className="club-product-card__price-old">1959</span>
            <p className="club-product-card__price-new">1959</p>
          </div>
        </div>
      </div>
      <h4 className="club-product-card__title product-title">АЗОВСКАЯ Мармелад, 200 г</h4>
      <span className="club-product-card__date">28.10-10.11</span>
    </div>
  )
}

export default ClubProductCard
