import React, { useState } from "react"

//components
import PopUp from "@components/shared/popup/PopUp"
import ReissueSmallCard from "@components/cabinet/popup-content/ReissueSmallCard"
import BonusCard from "@components/cabinet/small-card/BonusCard"

//temporary data
const tempData = {
  bonuses: 0,
  cardNumber: 4905533152300,
}

const SmallCardActive = () => {
  const [isReissueCardPopUpShown, setReissueCardPopUpState] = useState(false)

  const ReissueCardPopUp = () => {
    return (
      <PopUp onClose={() => setReissueCardPopUpState(false)}>
        <ReissueSmallCard onClose={() => setReissueCardPopUpState(false)} />
      </PopUp>
    )
  }

  return (
    <>
      <div className="small-card-active flex">
        <BonusCard isActive={true} bonuses={tempData.bonuses} cardNumber={tempData.cardNumber} />

        <div className="small-card-active__content">
          <button
            type="button"
            className="small-card-active__button button-readmore"
            onClick={() => setReissueCardPopUpState(true)}
          >
            Перевыпустить карту
          </button>
          <p className="small-card-active__text-about-app">
            Пластиковую карту вы можете получить в любом магазине Small. Для участия в программе
            лояльности не обязательно иметь пластиковую карту, достаточно использовать
            <a href="#" className="link--red" target={"_blank"}>
              {" наше приложение "}
            </a>
          </p>
        </div>
      </div>

      {isReissueCardPopUpShown && <ReissueCardPopUp />}
    </>
  )
}

export default SmallCardActive
