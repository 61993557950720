import http from "@api"

const enpoints = {
  register: "/auth/register",
  login: "/auth/login",
}

const authService = {
  handleRegister: async (data) => {
    const response = await http.post(enpoints.register, data)

    if (response.data) {
      const token = response.data.token
      localStorage.setItem("token", token)
    }

    return response.data
  },

  handleLogin: async (data) => {
    const response = await http.post(enpoints.login, data)

    if (response.data) {
      const token = response.data.token
      localStorage.setItem("token", token)
    }

    return response.data
  },
}

export default authService
