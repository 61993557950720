import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//store
import { loadUserData } from '@/store/slices/profileSlice'

//components
import PromoSlider from '@components/cabinet/sliders/PromoSlider'
import UserInfo from '@components/cabinet/profile/UserInfo'
import PurchaseAnalysisCard from '@components/cabinet/profile/PurchaseAnalysisCard'
import FavoriteCardDefault from '@components/cabinet/profile/FavoriteCardDefault'
import ClubCardSmall from '@components/cabinet/club/ClubCardSmall'

const ProfilePage = () => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.profileSlice.user)
  
  useEffect(() => {
    dispatch(loadUserData())
  }, [])

  return (
    <div className="profile-page">
      <div className="profile-page__slider-wrapper">
        <PromoSlider />
      </div>
      <div className="profile-page__content grid">
        <UserInfo
          firstName={user?.firstName}
          lastName={user?.lastName}
          email={user?.email}
          className="profile-page__user-info"
        />
        <PurchaseAnalysisCard />
        <FavoriteCardDefault className="profile-page__favorite-card" />

        <div className="profile-page__club-list-wrapper">
          <h4 className="profile-page__club-list-title">Мои клубы</h4>
          <div className="profile-page__club-list flex">
            <ClubCardSmall className="profile-page__club-item" backgroundColor="#91D1FF" />
            <ClubCardSmall className="profile-page__club-item" backgroundColor="#91D1FF" />
            <ClubCardSmall className="profile-page__club-item" backgroundColor="#91D1FF" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
