import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import clubsAPI from '@/services/clubsService'

//init
const name = 'clubsSlice'
const initialState = {
  clubs: [],
  isSubmitting: false,
}

//slice
export const clubsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setClubs: (state, action) => {
      state.clubs = action.payload
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
  },
  extraReducers: (builder) => {
    //loadClubs
    builder.addCase(loadClubs.fulfilled, (state, action) => {
      state.clubs = action.payload
    })
  },
})

//thunks
export const loadClubs = createAsyncThunk(`${name}/loadClubs`, async () => {
  try {
    const response = await clubsAPI.getAllClubs()
    return response
  } catch (error) {
    console.log(error)
  }
})

export const joinClub = createAsyncThunk(`${name}/joinClub`, async (clubId, { dispatch }) => {
  dispatch(setIsSubmitting(true))
  try {
    const response = await clubsAPI.handleJoinClub(clubId)
    return response
  } catch (error) {
    console.log(error)
  } finally {
    dispatch(setIsSubmitting(false))
  }
})

export const leaveClub = createAsyncThunk(`${name}/leaveClub`, async (clubId, { dispatch }) => {
  dispatch(setIsSubmitting(true))
  try {
    const response = await clubsAPI.handleLeaveClub(clubId)
    return response
  } catch (error) {
    console.log(error)
  } finally {
    dispatch(setIsSubmitting(false))
  }
})

//actions
export const { setClubs, setIsSubmitting } = clubsSlice.actions

export default clubsSlice.reducer
