import React from "react"
import { Outlet } from "react-router-dom"

//components
import BirthdayBanner from "@components/cabinet/bonus-coupon/birthday-banner/BirthdayBanner"

const ClubsPage = () => {
  return (
    <div className="clubs-page">
      <BirthdayBanner />
      <Outlet />
    </div>
  )
}

export default ClubsPage
