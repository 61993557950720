import React, { useState } from "react"
import { Link } from "react-router-dom"
import cn from "classnames"

//components
import ClubProductCard from "@components/cabinet/product-cards/ClubProductCard"
//assets
import Image from "@assets/images/temp/club-inner-page-img.png"

const ClubInnerPage = () => {
  const [inClub, setInClub] = useState(false)
  const buttonClassName = inClub ? "button--red" : "button--outlined"

  const toggleInClub = () => {
    setInClub(!inClub)
  }

  return (
    <div className={"club-inner-page"}>
      <div className={"club-inner-page__content"}>
        <div className={"club-inner-page__content-inner"}>
          <div className={"club-inner-page__content-left"}>
            <div className={"club-inner-page__image-wrapper"}>
              <div className={"club-inner-page__image"}>
                <img src={Image} />
              </div>
            </div>
            <div className={"club-inner-page__actions"}>
              {/* {inClub && inThisClub && (
                <button className={"button club-inner-page__button button--red-light"}>
                  Вы состоите в клубе
                </button>
              )} */}

              <button
                className={cn("button club-inner-page__button", buttonClassName)}
                onClick={() => toggleInClub()}
              >
                {inClub ? "Покинуть клуб" : "Вступить в клуб"}
              </button>
            </div>
          </div>
          <div className="club-inner-page__content-right">
            <h2 className="club-inner-page__content-title">Клуб “ЗОЖ”</h2>
            <p className="club-inner-page__content-desc">
              Мы профессионалы и каждый член команды отвечает за общий результат профессионалы и
              каждый член команды отвечает за общий результат Мы профессионалы и каждый член команды
              отвечает за общий результат профессионалы и каждый член команды отвечает за общий
              результатМы профессионалы и каждый
            </p>
            <div className="club-inner-page__content-conditions">
              <h4>Условия клуба: </h4>
              <p>
                Клуб можно менять не чаще 1 раза в месяц. Список SKU обновляется 1 раз в 1 месяц
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={"club-inner-page__content--mobile"}>
        <div className="club-inner-page__content-header">
          <div className={"club-inner-page__image"}>
            <img src={Image} />
          </div>
          <h2 className="club-inner-page__content-title">Клуб “ЗОЖ”</h2>
        </div>
        <p className="club-inner-page__content-desc">
          Мы профессионалы и каждый член команды отвечает за общий результат профессионалы и каждый
          член команды отвечает за общий результат Мы профессионалы и каждый член команды отвечает
          за общий результат профессионалы и каждый член команды отвечает за общий результатМы
          профессионалы и каждый
        </p>
        <div className={"club-inner-page__actions"}>
          {/* {inClub && inThisClub && (
                <button className={"button club-inner-page__button button--red-light"}>
                  Вы состоите в клубе
                </button>
              )} */}

          <button
            className={cn("button club-inner-page__button", buttonClassName)}
            onClick={() => toggleInClub()}
          >
            {inClub ? "Покинуть клуб" : "Вступить в клуб"}
          </button>
        </div>
      </div>

      <div className="club-inner-page__products">
        <div className="club-inner-page__products-header">
          <h3 className="club-inner-page__products-title secondary-title">
            Товары для этого клуба
          </h3>
          <Link to="/cabinet" className="club-inner-page__products-button button-see-all">
            Смотреть все
          </Link>
        </div>
        <div className="club-inner-page__products-list list">
          <ClubProductCard />
          <ClubProductCard />
          <ClubProductCard />
        </div>
      </div>
    </div>
  )
}

export default ClubInnerPage
