import React from "react"
import ReactECharts from "echarts-for-react"

export default function BarBrush() {
  const options = {
    xAxis: {
      data: ["Май", "Июнь", "Июль"],
    },
    yAxis: {},
    series: [
      {
        data: [
          {
            value: 0,
            itemStyle: {
              color: "#51CF66",
            },
          },
          {
            value: 0,
            itemStyle: {
              color: "#51CF66",
            },
          },
          {
            value: 0,
            itemStyle: {
              color: "#51CF66",
            },
          },
        ],
        type: "bar",
        stack: "x",
      },
      {
        data: [
          {
            value: 0,
            itemStyle: {
              color: "rgba(81, 207, 102, 0.3)",
            },
          },
          {
            value: 0,
            itemStyle: {
              color: "rgba(81, 207, 102, 0.3)",
            },
          },
          {
            value: 0,
            itemStyle: {
              color: "rgba(81, 207, 102, 0.3)",
            },
          },
        ],
        type: "bar",
        stack: "x",
      },
    ],
  }
  const onChartClick = (e) => {
    e.color = "#000"
    options.series[0].data.forEach((element) => {
      element.itemStyle.color = "#000"
    })
  }
  return (
    <ReactECharts
      option={options}
      style={{ height: "300px", width: "280px" }}
      onEvents={{
        mouseover: onChartClick,
      }}
    />
  )
}
