import React from "react-dom"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { NavLink, useLocation } from "react-router-dom"
import { toggleMenu } from "@/store/slices/uiSlice"

export default function CabinetNavigation() {
  const location = useLocation()
  const dispatch = useDispatch()
  const isVisibleMenu = useSelector((state) => state.uiSlice.isVisibleMenu)

  useEffect(() => {
    if (isVisibleMenu) {
      dispatch(toggleMenu())
    }
  }, [location])

  return (
    <div>
      <button className="nav-close-button" onClick={() => dispatch(toggleMenu())}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L18 18M2 18L18 2L2 18Z"
            stroke="#A2A2A2"
            strokeWidth="2.27273"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <h1 className="title">Личный кабинет</h1>
      <nav className="cabinet-nav flex">
        <NavLink className="cabinet-nav__item " to="/cabinet/profile">
          Мой профиль
        </NavLink>
        <NavLink className="cabinet-nav__item" to="/cabinet/small-card">
          Карта SMALL
        </NavLink>
        <NavLink className="cabinet-nav__item" to="/cabinet/bonuses-coupons">
          Бонусы и купоны
        </NavLink>
        <NavLink className="cabinet-nav__item" to="/cabinet/my-purchases">
          Мои покупки
        </NavLink>
        <NavLink className="cabinet-nav__item" to="/cabinet/clubs">
          Клубы по интересам
        </NavLink>
        <NavLink className="cabinet-nav__item" to="/cabinet/contacts">
          Контактная информация
        </NavLink>
        <NavLink className="cabinet-nav__item logout-button flex" to="/">
          <span className="icon logout-button__icon"></span>
          Выйти
        </NavLink>
      </nav>
    </div>
  )
}
