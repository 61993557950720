import React, { useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import styles from "./RangeDatepicker.module.scss"

export default function RangeDatepicker() {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const CustomInput = React.forwardRef(({ value, onChange, onClick }, ref) => {
    return (
      <div className={`${styles["range-datepicker-wrapper"]}`}>
        <input
          className={`${styles["range-datepicker"]}`}
          value={value}
          onClick={onClick}
          onChange={(e) => onChange(e.target.value)}
          placeholder="19.12.2022 - 25.12.2022"
          ref={ref}
        />
        <button
          className={`${styles["icon-calendar"]} button`}
          onClick={onClick}
        ></button>
        <button
          className={`${styles["icon-arrow-down"]} button`}
          onClick={onClick}
        ></button>
      </div>
    )
  })

  return (
    <DatePicker
      dateFormat="dd.MM.yyyy"
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      customInput={<CustomInput />}
    />
  )
}
