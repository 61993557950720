import React, { useState } from "react"
//import { useDispatch } from "react-redux"
//import { useNavigate } from "react-router-dom"

//api
import authAPI from "@/services/authService"

//store
// import { register } from "@/store/slices/authSlice"
// import { toggleIsOpenAuthForm } from "@/store/slices/uiSlice"

//hooks
import useFormField from "@/utils/hooks/useFormField"

//components
import TextInputField from "@components/shared/form/TextInputField"
import PhoneNumberInputField from "@components/shared/form/PhoneNumberInputField"
import PasswordInputField from "@components/shared/form/PasswordInputField"
import CheckboxInputField from "@components/shared/form/CheckboxInputField"
import AuthActions from "./AuthActions"

const RegisterForm = ({ changeFormType }) => {
  //const dispatch = useDispatch()
  //const navigate = useNavigate()

  // state
  const [ownSmallCard, setOwnSmallCard] = useState(false)
  const [confirmAgreement, setConfirmAgreement] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // fields
  const name = useFormField("")
  const email = useFormField("")
  const phone = useFormField("")
  const password = useFormField("")
  const confirmPassword = useFormField("")
  const cardNumber = useFormField("")

  const formFields = {
    name,
    email,
    phone,
    password,
    confirmPassword,
  }

  // handlers
  const toggleOwnSmallCard = () => {
    setOwnSmallCard((old) => !old)
  }
  const toggleConfirmAgreement = () => {
    setConfirmAgreement((old) => !old)
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()

    //validate form fields before sumbit
    if (Object.values(formFields).some((field) => field.input.error)) {
      Object.values(formFields).forEach((field) => field.setters.showError())
      return
    }

    if (password.input.value !== confirmPassword.input.value) {
      confirmPassword.setters.setError("Passwords do not match!")
      confirmPassword.setters.showError()
      return
    }

    //submit implementation
    setIsSubmitting(true)
    try {
      const response = await authAPI.handleRegister({
        name: name.input.value,
        email: email.input.value,
        phone: phone.input.value,
        password: password.input.value,
      })
      console.log(response)
    } catch (error) {
      console.log(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form className={"auth-form"} onSubmit={handleSubmitForm}>
      <h4 className={"auth-form__title"}>Регистрация</h4>
      <div className={"auth-form__body auth-register-form__body"}>
        {/* Name */}
        <TextInputField meta={name} type="text" placeholder="Имя" />

        {/* Email */}
        <TextInputField meta={email} type="email" placeholder="Почта" />

        {/* Login */}
        <PhoneNumberInputField meta={phone} type="text" placeholder="Номер телефона" />

        {/* Password */}
        <PasswordInputField meta={password} placeholder="Пароль" />

        {/* Comfirm Password */}
        <PasswordInputField meta={confirmPassword} placeholder="Подтвердите пароль" />

        {/* Own Small Card */}
        <CheckboxInputField
          value={ownSmallCard}
          id="own-small-card"
          groupClasses="auth-register-form__checkbox"
          onChange={toggleOwnSmallCard}
        >
          <span>У меня есть карта Small</span>
        </CheckboxInputField>
        {ownSmallCard && (
          <TextInputField meta={cardNumber} type="number" placeholder="Введите номер карты" />
        )}

        {/* Own Small Card */}
        <CheckboxInputField
          value={confirmAgreement}
          id="confirm-agreement"
          groupClasses="auth-register-form__checkbox"
          onChange={toggleConfirmAgreement}
        >
          <span className="auth-register-form__agreement-label">
            Я подтверждаю, что ознакомлен и согласен с условиями{" "}
            <a href="https://www.google.com/" target={"_blank"}>
              Полити конфиденциальности
            </a>
          </span>
        </CheckboxInputField>
      </div>

      <div className={"auth-form__footer"}>
        <AuthActions
          changeButtonLabel="Авторизация"
          changeFormType={() => changeFormType("login")}
          actionButtonLabel="Зарегистрироваться"
          actionButtonDisabled={!confirmAgreement || isSubmitting}
          onAction={handleSubmitForm}
        />
      </div>
    </form>
  )
}

export default RegisterForm
