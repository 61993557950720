import React from "react"
import cn from "classnames"

//components
import CircleSatus from "@components/shared/icons/CircleStatus"

const BonusCard = ({ isActive, bonuses = 0, cardNumber = "" }) => {
  return (
    <div className={cn("bonus-card", isActive && "active")}>
      <h4 className="bonus-card__header">
        <span className="bonus-card__label">Мои бонусы </span>
        <span className="bonus-card__value">{bonuses}</span>
      </h4>
      <p className="bonus-card__number">{cardNumber}</p>
      <div className="bonus-card__status-wrapper">
        <CircleSatus isActive={isActive} />
      </div>
    </div>
  )
}

export default BonusCard
