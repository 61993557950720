import React from "react"

//assets
import PrductImage from "@assets/images/temp/product.png"

const ProductMoreInfo = () => {
  return (
    <div className="product-more-info">
      <div className="product-more-info__header">
        <div className="product-more-info__image">
          <img src={PrductImage} />
        </div>
        <div className="product-more-info__content">
          <h4 className="product-more-info__title product-title--bold">АЗОВСКАЯ Мармелад, 200 г</h4>
          <div className="product-more-info__wrapper">
            <div className="product-more-info__date">
              <p>-70%</p>
              <span>до 15.07.2020</span>
            </div>
            <div className="product-more-info__bonuses">
              300 <span className="product-more-info__bonuses-text">Бонусов</span>
              <span className="product-more-info__bonuses-text--mobile">Б</span>
            </div>
          </div>
        </div>
      </div>
      <div className="product-more-info__desc">
        <h4>Описание товара:</h4>
        <p>
          Мармелад фасованный Веселые мишки 1кг - фруктовый жевательный мармелад с натуральным соком
          выполненный в форме цветных мишек. Каждому цвету соответствует свой вкус. Мармеладные
          мишки станут любимым лакомством всех детей, они очень вкусные, в меру сладкие, с
          насыщенным фруктовым вкусом и приятной кислинкой. Кроме того, мармелад можно использовать
          как декор для домашних пирожных и тортов.
        </p>
      </div>
      <div className="product-more-info__actions">
        <button className="product-more-info__button button button--red">Купить</button>
      </div>
    </div>
  )
}

export default ProductMoreInfo
