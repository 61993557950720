import React from "react"
import styles from "./TransactionTable.module.scss"

export default function TransactionTable() {
  const items = [
    { id: 1, date: "19/02/2022", shop: "Magaz", summary: "45.00", bonus: 450 },
    { id: 2, date: "19/02/2023", shop: "Magaz 2", summary: "555.00", bonus: 450 },
    { id: 3, date: "19/02/2024", shop: "Magaz 4", summary: "111.00", bonus: 222 },
  ]

  const showCheque = (item) => {
    console.log(item)
  }

  const tableRows = items.map((item) => {
    const rawDate = item.date.split("/").reverse()
    const date = new Date(rawDate[0], rawDate[1], rawDate[2])
    const day = date.getDate()
    const month = date.getMonth() + 1

    return (
      <div className={`${styles["body__row"]}`} key={item.id}>
        <div>
          <span className={styles["month-desktop"]}>{item.date}</span>
          <span className={styles["month-mobile"]}>{`${day}.${
            month < 10 ? "0" + month : month
          }`}</span>
        </div>
        <div>{item.shop}</div>
        <div className={styles.money}>
          <span className={styles["money__value"]}> {item.summary} </span>
          <span className={styles["money-desktop"]}>тенге</span>
          <span className={styles["money-mobile"]}>
            <svg
              width="7"
              height="9"
              viewBox="0 0 7 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                y1="2.39453"
                x2="7"
                y2="2.39453"
                stroke="#A2A2A2"
                strokeWidth="1.5"
              />
              <line y1="0.5" x2="7" y2="0.5" stroke="#A2A2A2" />
              <line
                x1="3.55078"
                y1="3.14453"
                x2="3.55078"
                y2="9.00037"
                stroke="#A2A2A2"
                strokeWidth="1.5"
              />
            </svg>
          </span>
        </div>
        <div className={`${styles["body__row-bonus"]}`}>
          <span>
            {item.bonus}
            <span className={styles["bonus-desktop"]}>бонусов</span>
            <span className={styles["bonus-mobile"]}> Б </span>
          </span>
          <button
            className={`${styles["button-show"]} button`}
            onClick={() => {
              showCheque(item)
            }}
          >
            {" "}
          </button>
        </div>
      </div>
    )
  })
  return (
    <div className={`${styles.table}`}>
      <div className={`${styles.header}`}>
        <div className={`${styles["header__item"]}`}>Дата</div>
        <div className={`${styles["header__item"]}`}>Магазин</div>
        <div className={`${styles["header__item"]}`}>Сумма покупки</div>
        <div className={`${styles["header__item"]}`}>Начислено бонусов</div>
      </div>
      <div className={`${styles["body"]}`}>{tableRows}</div>
    </div>
  )
}
