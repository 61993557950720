const fieldValidator = (value, minLen = 3, maxLen = 20) => {
  if (value === "") {
    return "Поле не может быть пустым!"
  }

  if (value.length <= minLen) {
    return "Must be at least 4 characters long"
  }

  if (value.length >= maxLen) {
    return "Must be less than 20 characters long"
  }

  return false
}

export { fieldValidator }
