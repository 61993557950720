import React, { useState } from 'react'
//import { useDispatch } from "react-redux"
//import { useNavigate } from "react-router-dom"

//api
import authAPI from '@/services/authService'

//store
// import { setIsLoggedIn } from "@/store/slices/authSlice"

//hooks
import useFormField from '@/utils/hooks/useFormField'

//components
import TextInputField from '@components/shared/form/TextInputField'
import PasswordInputField from '@components/shared/form/PasswordInputField'
import AuthActions from './AuthActions'

const LoginForm = ({ changeFormType }) => {
  //const dispatch = useDispatch()
  //const navigate = useNavigate()

  // state
  const [isSubmitting, setIsSubmitting] = useState(false)

  // fields
  const email = useFormField('')
  const password = useFormField('')

  const formFields = {
    email,
    password,
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()

    //validate form fields before sumbit
    if (Object.values(formFields).some((field) => field.input.error)) {
      Object.values(formFields).forEach((field) => field.setters.showError())

      return
    }

    //submit implementation
    setIsSubmitting(true)
    try {
      const response = await authAPI.handleLogin({
        email: email.input.value,
        password: password.input.value,
      })
      console.log(response)
    } catch (error) {
      console.log(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form className={'auth-form'} onSubmit={handleSubmitForm}>
      <h4 className={'auth-form__title'}>Авторизация</h4>
      <div className={'auth-form__body'}>
        {/* Login */}
        <TextInputField meta={email} type="text" placeholder="Email" />
        {/* Password */}
        <PasswordInputField meta={password} placeholder="Пароль" />

        {/* Forget Password */}
        <div className={'auth-login-form__forget-password form__group'}>
          <a className="button-readmore" onClick={() => changeFormType('forget-password')}>
            Забыли пароль?
          </a>
        </div>
      </div>
      <div className={'auth-form__footer'}>
        <AuthActions
          changeButtonLabel="Регистрация"
          changeFormType={() => changeFormType('signup')}
          actionButtonLabel="Войти"
          actionButtonDisabled={isSubmitting}
          onAction={handleSubmitForm}
        />
      </div>
    </form>
  )
}

export default LoginForm
