import React, { useState } from "react"
import { useDispatch } from "react-redux"

//store
import { setUserHasCard } from "@/store/slices/profileSlice"

//hooks
import useFormField from "@/utils/hooks/useFormField"

//components
import CardNumberInputField from "@components/shared/form/CardNumberInputField"
import PopUp from "@components/shared/popup/PopUp"
import SuccessfulCompletion from "@components/cabinet/popup-content/SuccessfulCompletion"

const BindPlasticCard = () => {
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const cardNumber = useFormField("")

  const CardLinkedSuccessPopUp = () => {
    return (
      <PopUp onClose={() => setIsSuccess(false)}>
        <SuccessfulCompletion text="Ваша карта успешно добавлена!" />
      </PopUp>
    )
  }

  const handleBindPlasticCard = (e) => {
    e.preventDefault()

    //submit implementation
    setIsSubmitting(true)
    setTimeout(() => {
      console.log(cardNumber)
      setIsSubmitting(false)
      setIsSuccess(true)
      dispatch(setUserHasCard(true))
    }, 2500)
  }

  return (
    <div className="bind-plastic-card">
      <form className="bind-plastic-card__form" onSubmit={handleBindPlasticCard}>
        <CardNumberInputField meta={cardNumber} placeholder="Введите номер карты" />
        <div className="bind-plastic-card__actions flex">
          <button
            type="submit"
            className="bind-plastic-card__button button button--red"
            disabled={isSubmitting}
            onClick={(e) => handleBindPlasticCard(e)}
          >
            Привязть пластиковую карту
          </button>
        </div>
      </form>

      {isSuccess && <CardLinkedSuccessPopUp />}
    </div>
  )
}

export default BindPlasticCard
