import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profileService from '@/services/profileService'

//init
const name = 'profileSlice'
const initialState = {
  user: {},
}

//slice
const profileSlice = createSlice({
  name,
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload
    },
    //fake action
    setUserHasCard: (state, action) => {
      state.hasCard = action.payload
    },
  },
  extraReducers: (builder) => {
    //loadClubs
    builder.addCase(loadUserData.fulfilled, (state, action) => {
      state.user = action.payload
    })
  },
})

//thunks
export const loadUserData = createAsyncThunk(`${name}/loadUserData`, async () => {
  try {
    const response = await profileService.getUserData()
    return response
  } catch (error) {
    console.log(error)
  }
})

//actions
export const { setUserData, setUserHasCard } = profileSlice.actions

export default profileSlice.reducer
