import React from "react"
import Header from "@/components/shared/Header.jsx"
import { useDispatch } from "react-redux"
import { toggleIsOpenAuthForm } from "@/store/slices/uiSlice"
import { useEffect } from "react"

export default function LoginPage() {
  // const isOpenAuthForm = useSelector((state) => state.uiSlice.isOpenAuthForm)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(toggleIsOpenAuthForm(true))
  })
  return (
    <div className="home">
      <Header />
    </div>
  )
}
