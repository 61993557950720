import React from "react"
import BirthdayImage from "@assets/images/temp/birthday-banner-bg.jpg"
import styles from "./BirthdayBanner.module.scss"

export default function BirthdayBanner() {
  return (
    <div
      className={`${styles["birthday-banner"]} flex`}
      style={{ backgroundImage: `url(${BirthdayImage})` }}
    >
      <div className={styles.left}>
        <p className={styles.title}>Мой баланс</p>
        <p className={styles.bonus}>50 Бонусов</p>
        <p className={styles.message}>1 бонус = 1 тенге</p>
      </div>
      <div className={`${styles.right} flex`}>
        <h4 className={styles.label}>С ДНЁМ РОЖДЕНИЯ!</h4>
      </div>
    </div>
  )
}
