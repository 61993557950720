import http from '@api'

const enpoints = {
  clubs: '/cabinet/segment',
  joinClub: (clubId) => `/cabinet/segment/add-client?segment_id=${clubId}`,
  leaveClub: (clubId) => `/cabinet/segment/remove-client?segment_id=${clubId}`,
}

const clubsService = {
  async getAllClubs() {
    const response = await http.get(enpoints.clubs)
    return response.data
  },

  async handleJoinClub(clubId) {
    const response = await http.post(enpoints.joinClub(clubId))
    return response.data
  },

  async handleLeaveClub(clubId) {
    const response = await http.post(enpoints.leaveClub(clubId))
    return response.data
  },
}

export default clubsService
