import React, { useState, useEffect } from "react"
import cn from "classnames"

//validators
import { fieldValidator } from "@/utils/validators/form-validators"

//formatters
import { formatPhone } from "@utils/formatters"

const TextInputField = ({
  required = false,
  type = "text",
  groupClasses = "",
  inputClasses = "",
  errorClasses = "",
  placeholder = "Поле",
  ...props
}) => {
  const [phoneMask, setPhoneMask] = useState("")
  const { input, setters } = props.meta
  const hasError = input.isErrorShown && input.error

  // validating filed requires first touch, to avoid this problem
  useEffect(() => {
    if (input.value === "") setters.setError(fieldValidator(input.value))
  }, [])

  const handleChange = (e) => {
    const value = e.target.value

    setPhoneMask(value)
    setters.changeValue(formatPhone(value))

    if (fieldValidator(value)) {
      setters.setError(fieldValidator(value))
    } else setters.setError(null)
  }

  const getInputNumbersValue = (input) => {
    return input.value.replace(/\D/g, "")
  }

  const handlePhonePaste = (e) => {
    const input = e.target,
      inputNumbersValue = getInputNumbersValue(input)
    const pasted = e.clipboardData || window.clipboardData
    if (pasted) {
      const pastedText = pasted.getData("Text")
      if (/\D/g.test(pastedText)) {
        // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
        // formatting will be in onPhoneInput handler
        input.value = inputNumbersValue
      }
    }
  }

  const handlePhoneInput = (e) => {
    let input = e.target,
      inputNumbersValue = getInputNumbersValue(input),
      selectionStart = input.selectionStart,
      formattedInputValue = ""

    if (!inputNumbersValue) {
      return (input.value = "")
    }

    if (input.value.length != selectionStart) {
      // Editing in the middle of input, not last symbol
      if (e.data && /\D/g.test(e.data)) {
        // Attempt to input non-numeric symbol
        input.value = inputNumbersValue
      }
      return
    }

    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
      if (inputNumbersValue[0] == "9") inputNumbersValue = `7${inputNumbersValue}`
      const firstSymbols = inputNumbersValue[0] == "8" ? "+7" : "+7"
      formattedInputValue = input.value = `${firstSymbols} `
      if (inputNumbersValue.length > 1) {
        formattedInputValue += `(${inputNumbersValue.substring(1, 4)}`
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += `) ${inputNumbersValue.substring(4, 7)}`
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += `-${inputNumbersValue.substring(7, 9)}`
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += `-${inputNumbersValue.substring(9, 11)}`
      }
    } else {
      formattedInputValue = `+${inputNumbersValue.substring(0, 16)}`
    }
    input.value = formattedInputValue
  }

  const handlePhoneKeyDown = (e) => {
    // Clear input after remove last symbol
    const inputValue = e.target.value.replace(/\D/g, "")
    if (e.keyCode == 8 && inputValue.length == 1) {
      e.target.value = ""
    }
  }

  return (
    <div className={cn("form__group", groupClasses)}>
      <input
        value={phoneMask}
        required={required}
        className={cn("form__input", inputClasses, hasError && "error")}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handlePhoneKeyDown}
        onPaste={handlePhonePaste}
        onInput={handlePhoneInput}
      />
      {hasError && <span className={cn("form__error", errorClasses)}>{input.error}</span>}
    </div>
  )
}

export default TextInputField
