import React, { useState, useEffect } from "react"
import cn from "classnames"

//assets
import ShowButton from "@assets/icons/password-show.svg"
import HideButton from "@assets/icons/password-hide.svg"

//validators
import { fieldValidator } from "@/utils/validators/form-validators"

const PasswordInputField = ({
  required = false,
  groupClasses = "",
  inputClasses = "",
  errorClasses = "",
  placeholder = "Поле",
  ...props
}) => {
  const [passwordIsShown, setPasswordShownState] = useState(false)
  const { input, setters } = props.meta
  const hasError = input.isErrorShown && input.error

  const togglePasswordShownState = () => {
    setPasswordShownState((oldState) => !oldState)
  }

  // validating filed requires first touch, to avoid this problem
  useEffect(() => {
    if (input.value === "") setters.setError(fieldValidator(input.value))
  }, [])

  const handleChange = (e) => {
    const value = e.target.value

    setters.changeValue(value)

    if (fieldValidator(value)) {
      setters.setError(fieldValidator(value))
    } else setters.setError(null)
  }

  return (
    <div className={cn("form__group form", groupClasses)}>
      <input
        value={input.value}
        required={required}
        className={cn("form__input form__password-input", inputClasses, hasError && "error")}
        type={passwordIsShown ? "text" : "password"}
        placeholder={placeholder}
        onChange={handleChange}
      />
      <div className="form__password-input-button" onClick={togglePasswordShownState}>
        {passwordIsShown ? <img src={HideButton} /> : <img src={ShowButton} />}
      </div>
      {hasError && <span className={cn("form__error", errorClasses)}>{input.error}</span>}
    </div>
  )
}

export default PasswordInputField
